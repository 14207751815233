import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Global, css } from '@emotion/react';

import { APPLE_STORE_LINK, GOOGLE_PLAY_LINK } from '../config';

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const AppDownloadLinks = styled.section`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap');
          body {
            margin: 20px;
            font-family: 'Raleway', sans-serif;
          }
        `}
      />
      <Header>
        <StaticImage src="../images/logo.png" alt="Globetrott logo" width={200} />
      </Header>
      <AppDownloadLinks>
        <a href={APPLE_STORE_LINK}>
          <StaticImage src="../images/apple-store.png" alt="Apple store" width={200} />
        </a>
        <a href={GOOGLE_PLAY_LINK}>
          <StaticImage src="../images/google-play.png" alt="Google play" width={200} />
        </a>
      </AppDownloadLinks>
      <ContentWrapper>{children}</ContentWrapper>
    </>
  );
};

export default Layout;
